import React from "react";
import GlobalStyles from "./src/styles/global";

export const wrapRootElement = ({ element }: any) => {
  return (
    <>
      <GlobalStyles />
      {element}
    </>
  );
};
